import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Giftoftheheartbreadcreumb from "../components/resources/gift-of-the-heart/gift-of-the-heart-breadcumb.jsx";

import GiftoftheheartContentpage from "../components/resources/gift-of-the-heart/gift-of-the-heart-content.jsx";


// markup
const Internationalday = () => {
  return (
    <div>
      <Seo title="Humans Of Heartfulness" />

      <Layout isSticky>
        <section>
          <Giftoftheheartbreadcreumb />
        </section>

        <section>
          <GiftoftheheartContentpage/>
        </section>
      </Layout>
    </div>
  );
};

export default Internationalday;
