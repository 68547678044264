import React from "react";

import cinemeditation from "../../../assets/images/resources/gift-of-the-heart.png";

import { Link } from "gatsby";

import fbicon from "../../../assets/images/resources/facebook (1).png";

import twittericon from "../../../assets/images/resources/twitter (1).png";

import whatsappicon from "../../../assets/images/resources/whatsapp.png";

import pintersticon from "../../../assets/images/resources/pinterest.png";

import linkdinicon from "../../../assets/images/resources/linkedin.png";

export default function Outersucesscontentpage() {
  return (
    <section className="outer-sucess-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <h4 class="hfn-author-name text-center padd-b10 mb-4">
            Anthea, Cape Town & Knysna
                </h4>
            <img
              className="img-fluid"
              src={cinemeditation}
              alt="Wellness banner"
            />

            <div class="entry-content">
           
              <div className="pad30">
                <h4 class="hfn-author-name text-center padd-b10">
                
                </h4>

                <div class="single-share-box">
                  <div class="share-link-description">Share this</div>
                  <div class="share-buttons">
                  <Link
                      class="facebook"
                      to="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F&amp;t=From+Inner+Awareness+to+Outer+Success"
                      title="Facebook"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={fbicon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="twitter"
                      to="https://twitter.com/share?url=https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F&amp;text=From+Inner+Awareness+to+Outer+Success"
                      title="Twitter"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={twittericon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="whatsapp"
                      to="https://api.whatsapp.com/send?text=From%20Inner%20Awareness%20to%20Outer%20Success%20-%20https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F"
                      title="WhatsApp"
                      target="_blank"
                      rel="noreferrer"
                      data-action="share/whatsapp/share"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={whatsappicon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="pinterest pinit-marklet"
                      to="//pinterest.com/pin/create/button/"
                      title="Pinterest"
                      target="_blank"
                      data-pin-config="above"
                      rel="noreferrer"
                      data-pin-do="buttonBookmark"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={pintersticon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="linkedin"
                      to="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F&amp;title=From%20Inner%20Awareness%20to%20Outer%20Success&amp;summary=&amp;source=Heartfulness%20Primary"
                      title="LinkedIn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={linkdinicon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                  <p className="p-tag-offerings">
                  It is a great blessing to have been introduced to such a beautiful meditation system. Each time that my heart is swept away in a river of honey, I come back cleansed, and with a more right attitude to the troubles of my life. The lesson of learning to ‘think with one’s heart’ spans many religions and spiritual practices.The practicality of how one can learn to do this, however, seems especially accessible in Heartfulness. Sometimes it may simply be a moment to pause and reset, to respond more creatively to a situation. 
                    </p>
                    <p className="p-tag-offerings">
                    I love that that this meditation practice is irrespective of one’s religious beliefs or socio-economic context. In Cape Town, we have a great diversity of cultural backgrounds. At times, brothers and sisters in the community may be celebrating a Hindu festival and offer delicious Indian sweet treats with tea after a group satsangh. At another time, other folk may be fasting in honour of a Jewish holy day. In Cape Town and Knysna, a few of the Heartfulness practitioners also facilitate psychotherapeutic workshops as their world work. Through the example of how this practice instills a sense of well-being, participants are drawn to joining the meditations, allowing for an experience of conscious living, that not only enhances and enriches, but revitalises life. 

                    </p>
                    <p className="p-tag-offerings">
                   
Since covid times, online distance meditation became more common. From the armchair in my bedroom, with a candle lit next to me, a window opens in my heart and I soar through a sacred hallway where thousands sit. Or, I may enter the quietly intimate sanctuary where each friend sits in their own precious reverie of the divine. These are gifts of the heart.
                    </p>
                   
                  </div>
                </div>
              </div>

              {/* <div class="entry-meta">
                <span class="category-link">
                  Category:&nbsp;
                  <a href={() => false}>
                    HOH Series
                  </a>
                </span>
                <a
                 href={() => false}
                  title="10:22 am"
                  class="data-link"
                  rel="noreferrer"
                >
                  <time
                    class="entry-date updated"
                    datetime="2021-08-09T10:22:26+05:30"
                  >
                    August 9, 2021
                  </time>
                </a>
              </div> */}
          

              <div class="single-share-box">
                <div class="share-link-description">Share this post</div>
                <div class="share-buttons">
                <Link
                      class="facebook"
                      to="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F&amp;t=From+Inner+Awareness+to+Outer+Success"
                      title="Facebook"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={fbicon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="twitter"
                      to="https://twitter.com/share?url=https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F&amp;text=From+Inner+Awareness+to+Outer+Success"
                      title="Twitter"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={twittericon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="whatsapp"
                      to="https://api.whatsapp.com/send?text=From%20Inner%20Awareness%20to%20Outer%20Success%20-%20https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F"
                      title="WhatsApp"
                      target="_blank"
                      rel="noreferrer"
                      data-action="share/whatsapp/share"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={whatsappicon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="pinterest pinit-marklet"
                      to="//pinterest.com/pin/create/button/"
                      title="Pinterest"
                      target="_blank"
                      data-pin-config="above"
                      rel="noreferrer"
                      data-pin-do="buttonBookmark"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={pintersticon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="linkedin"
                      to="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F&amp;title=From%20Inner%20Awareness%20to%20Outer%20Success&amp;summary=&amp;source=Heartfulness%20Primary"
                      title="LinkedIn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={linkdinicon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                </div>
              </div>

             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
